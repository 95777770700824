<template>
    <div class="container-fluid">
      <h1 class="mt-4">Recruiter Performance Dashboard</h1>
  
      <!-- Filters -->
      <b-row class="mb-3">
        <b-col md="3">
          <label for="recruiterSelect">Select Recruiter:</label>
          <b-form-select id="recruiterSelect" v-model="selectedRecruiter" :options="recruiterOptions" @change="fetchData"></b-form-select>
        </b-col>
        <b-col md="3">
          <label for="timeframeSelect">Timeframe:</label>
          <b-form-select id="timeframeSelect" v-model="selectedTimeframe" :options="timeframeOptions" @change="fetchData"></b-form-select>
        </b-col>
        <b-col md="4">
          <label for="searchInput">Search:</label>
          <b-form-input type="text" id="searchInput" v-model="searchQuery" @keyup.enter="searchData" placeholder="e.g., John Doe's offers last quarter"></b-form-input>
        </b-col>
        <b-col md="2">
          <b-button variant="primary" @click="searchData" class="mt-4" :disabled="loading">
            <b-spinner v-if="loading" small></b-spinner>
            <span v-else>Search</span>
          </b-button>
          <b-button variant="secondary" @click="clearSearch" class="mt-4 ml-2" :disabled="!searchQuery">Clear</b-button>
        </b-col>
      </b-row>
  
      <!-- Display Search Result Message (if any) -->
      <b-row v-if="searchMessage">
        <b-col md="12">
          <b-alert show variant="info">{{ searchMessage }}</b-alert>
        </b-col>
      </b-row>
  
      <!-- Performance Cards -->
      <b-row v-if="!isMonthWise && !searchMessage">
        <b-col md="3" v-if="!loading">
          <b-card :title="'Shortlisted'" bg-variant="light" text-variant="dark" class="text-center">
            <b-card-text>{{ performanceData.shortlistedCount || 0 }}</b-card-text>
          </b-card>
        </b-col>
        <b-col md="3" v-if="!loading">
          <b-card :title="'Offered'" bg-variant="light" text-variant="dark" class="text-center">
            <b-card-text>{{ performanceData.offeredCount || 0 }}</b-card-text>
          </b-card>
        </b-col>
        <b-col md="3" v-if="!loading">
          <b-card :title="'Joiners'" bg-variant="light" text-variant="dark" class="text-center">
            <b-card-text>{{ performanceData.joinersCount || 0 }}</b-card-text>
          </b-card>
        </b-col>
        <b-col md="3" v-if="!loading">
          <b-card :title="'Drops'" bg-variant="light" text-variant="dark" class="text-center">
            <b-card-text>{{ performanceData.dropsCount || 0 }}</b-card-text>
          </b-card>
        </b-col>
        <b-col md="3" v-if="!loading">
          <b-card :title="'Revenue'" bg-variant="light" text-variant="dark" class="text-center">
            <b-card-text>{{ performanceData.totalRevenue ? '$' + performanceData.totalRevenue.toFixed(2) : 0 }}</b-card-text>
          </b-card>
        </b-col>
      </b-row>
  
      <!-- For Month Wise -->
      <b-row v-if="isMonthWise && !searchMessage">
        <b-col md="12">
          <apexchart type="line" height="350" :options="chartOptions" :series="chartSeries"></apexchart>
        </b-col>
      </b-row>
  
      <!-- Top/Bottom Performers -->
      <b-row class="mt-4" v-if="!selectedRecruiter && selectedTimeframe !== 'monthwise' && !searchMessage">
        <b-col md="6">
          <h2>Top 3 Performers</h2>
          <b-list-group>
            <b-list-group-item v-for="recruiter in topPerformers" :key="recruiter.recruiterId">
              {{ recruiter.recruiterName }} (Score: {{ recruiter.score.toFixed(2) }})
            </b-list-group-item>
          </b-list-group>
        </b-col>
        <b-col md="6">
          <h2>Bottom 3 Performers</h2>
          <b-list-group>
            <b-list-group-item v-for="recruiter in bottomPerformers" :key="recruiter.recruiterId">
              {{ recruiter.recruiterName }} (Score: {{ recruiter.score.toFixed(2) }})
            </b-list-group-item>
          </b-list-group>
        </b-col>
      </b-row>
      <!-- Display Search Results (if any and not a message)-->
      <div v-if="searchResults && !searchMessage" class="mt-4">
        <div v-if="Array.isArray(searchResults)">
          <!-- Displaying List of Recruiters with respective data -->
          <b-table striped hover :items="searchResults" :fields="searchResultFields"></b-table>
        </div>
        <div v-else>
          <!-- Displaying Top and Bottom Performer -->
          <div class="row">
            <div class="col-md-6">
              <h2>Top 3 Performers</h2>
              <b-list-group>
                <b-list-group-item v-for="recruiter in searchResults.topPerformers" :key="recruiter.recruiterId">
                  {{ recruiter.recruiterName }} (Score: {{ recruiter.score.toFixed(2) }})
                </b-list-group-item>
              </b-list-group>
            </div>
            <div class="col-md-6">
              <h2>Bottom 3 Performers</h2>
              <b-list-group>
                <b-list-group-item v-for="recruiter in searchResults.bottomPerformers" :key="recruiter.recruiterId">
                  {{ recruiter.recruiterName }} (Score: {{ recruiter.score.toFixed(2) }})
                </b-list-group-item>
              </b-list-group>
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import axios from 'axios';
  import moment from 'moment';
  import VueApexCharts from 'vue-apexcharts'; // Import ApexCharts for Vue
  
  // Import BootstrapVue components explicitly
  import {
    BRow,
    BCol,
    BFormSelect,
    BFormInput,
    BButton,
    BSpinner,
    BAlert,
    BCard,
    BCardText,
    BListGroup,
    BListGroupItem,
    BTable
  } from 'bootstrap-vue';
  
  
  export default {
    components: {
      apexchart: VueApexCharts,
      BRow,
      BCol,
      BFormSelect,
      BFormInput,
      BButton,
      BSpinner,
      BAlert,
      BCard,
      BCardText,
      BListGroup,
      BListGroupItem,
      BTable // Register BootstrapVue components
    },
    data() {
      return {
        recruiters: [],
        selectedRecruiter: '',
        selectedTimeframe: 'ytd',
        performanceData: {},
        topPerformers: [],
        bottomPerformers: [],
        searchQuery: '',
        loading: false,
        searchResults: null,
        searchMessage: null,
        searchResultFields: [],
        chartOptions: {  // Options for ApexCharts
          chart: {
            id: 'recruiter-performance',
            type: 'line',
            zoom: {
              enabled: false
            }
          },
          dataLabels: {
            enabled: false
          },
          stroke: {
            curve: 'smooth',
            width: 3
          },
          title: {
            text: 'Recruiter Performance (Month-wise)',
            align: 'left'
          },
          grid: {
            row: {
              colors: ['#f3f3f3', 'transparent'], // Optional: alternate row colors
              opacity: 0.5
            },
          },
          xaxis: {
            type: 'datetime', // Important for time-based data
            labels: {
              format: 'MMM yyyy', // Customize date format
            }
          },
          yaxis: {
            title: {
              text: 'Count/Revenue' // General Y-axis title
            },
              labels: {
                formatter: function (value) {
                  return value; // Format as currency
                }
              }
          },
          tooltip: {
            x: {
              format: 'dd MMM yyyy' // Tooltip date format
            }
          }
        },
        chartSeries: [], // Data series for ApexCharts
        recruiterOptions: [{ value: '', text: 'All Recruiters' }],  // Options for recruiter select
        timeframeOptions: [ // Options for timeframe select
          { value: 'ytd', text: 'YTD' },
          { value: 'last_6_months', text: 'Last 6 Months' },
          { value: 'last_quarter', text: 'Last Quarter' },
          { value: 'current_quarter', text: 'Current Quarter' },
          { value: 'monthwise', text: 'Month Wise' },
        ],
      };
    },
    computed: {
      isMonthWise() {
        return this.selectedTimeframe === 'monthwise';
      }
    },
    watch: {
      selectedRecruiter(newRecruiter, oldRecruiter) {
        if (newRecruiter !== oldRecruiter) {
          this.clearSearchData();
        }
      },
      selectedTimeframe(newTimeframe, oldTimeframe) {
        if (newTimeframe !== oldTimeframe) {
          this.clearSearchData();
        }
      }
    },
    async created() {
      await this.fetchRecruiters();
      await this.fetchData(); // Initial data fetch
    },
    methods: {
      async fetchRecruiters() {
        try {
          const response = await axios.get('http://192.168.1.81:5000/api/users');
          this.recruiters = response.data.filter(user => user.type === 'recruiter');
          // Create options for the select dropdown
          this.recruiterOptions.push(...this.recruiters.map(r => ({ value: r.id, text: `${r.first_name} ${r.last_name}` })));
        } catch (error) {
          console.error('Error fetching recruiters:', error);
        }
      },
      async fetchChartData() {
        this.loading = true;
  
        try {
          let shortlistedData = [];
          let offeredData = [];
          let joinedData = [];
          let dropsData = [];
          let revenueData = [];
          let labels = [];
  
          if (this.selectedRecruiter) { // Fetch based on selection
            shortlistedData = await this.fetchMonthWiseData('shortlisted');
            offeredData = await this.fetchMonthWiseData('offered');
            joinedData = await this.fetchMonthWiseData('joiners');
            dropsData = await this.fetchMonthWiseData('drops');
            revenueData = await this.fetchMonthWiseData('revenue');
              if (shortlistedData.length) {
                  labels = shortlistedData.map(item => item.month); //Use original date
              }
  
          } else { // Fetch all data and consolidate it
              const allRecruiters = this.recruiters;
              const allShortlistedData = await Promise.all(allRecruiters.map(recruiter => this.fetchMonthWiseData('shortlisted', recruiter.id)));
              const allOfferedData = await Promise.all(allRecruiters.map(recruiter => this.fetchMonthWiseData('offered', recruiter.id)));
              const allJoinedData = await Promise.all(allRecruiters.map(recruiter => this.fetchMonthWiseData('joiners', recruiter.id)));
              const allDropsData = await Promise.all(allRecruiters.map(recruiter => this.fetchMonthWiseData('drops', recruiter.id)));
              const allRevenueData = await Promise.all(allRecruiters.map(recruiter => this.fetchMonthWiseData('revenue', recruiter.id)));
  
              const aggregatedData = {};
  
              const processData = (dataArray, metricKey) => {
                dataArray.forEach(recruiterData => {
                  recruiterData.forEach(item => {
                      const month = item.month; // Use the original month
                    if (!aggregatedData[month]) {
                      aggregatedData[month] = { month };
                    }
                      aggregatedData[month][metricKey] = (aggregatedData[month][metricKey] || 0) + (item[metricKey] || 0);
  
                      if(metricKey === 'totalRevenue'){ //cumulative
                        const months = Object.keys(aggregatedData).sort();
                        let cumulativeRevenue = 0;
  
                        months.forEach(m => {
                          cumulativeRevenue += aggregatedData[m].totalRevenue || 0;
                          aggregatedData[m].totalRevenue = cumulativeRevenue;
                        });
                      }
                  });
                });
              };
  
              processData(allShortlistedData, 'shortlistedCount');
              processData(allOfferedData, 'offeredCount');
              processData(allJoinedData, 'joinersCount');
              processData(allDropsData, 'dropsCount');
              processData(allRevenueData, 'totalRevenue')
  
              labels = Object.keys(aggregatedData).sort(); // Sort months
  
              shortlistedData = labels.map(month => ({x: new Date(aggregatedData[month].month), y: aggregatedData[month].shortlistedCount || 0}));
              offeredData = labels.map(month =>  ({x: new Date(aggregatedData[month].month), y: aggregatedData[month].offeredCount || 0}));
              joinedData = labels.map(month => ({x: new Date(aggregatedData[month].month), y: aggregatedData[month].joinersCount || 0}));
              dropsData = labels.map(month =>  ({x: new Date(aggregatedData[month].month), y: aggregatedData[month].dropsCount || 0}));
              revenueData = labels.map(month => ({x: new Date(aggregatedData[month].month), y: aggregatedData[month].totalRevenue || 0}))
            }
  
          // Prepare series data for ApexCharts
          this.chartSeries = [
            { name: 'Shortlisted', data: shortlistedData.map(item => ({x: item.x, y: item.y})) },
            { name: 'Offered', data: offeredData.map(item => ({x: item.x, y: item.y}))  },
            { name: 'Joiners', data: joinedData.map(item => ({x: item.x, y: item.y}))  },
            { name: 'Drops', data: dropsData.map(item => ({x: item.x, y: item.y}))  },
            { name: 'Revenue', data: revenueData.map(item => ({x: item.x, y: item.y}))  }
          ];
          this.chartOptions.xaxis.categories = labels;
  
        } catch (error) {
          console.error("Error fetching chart data: ", error);
        } finally {
          this.loading = false;
        }
      },
  
        async fetchMonthWiseData(metric, recruiterId = this.selectedRecruiter) {
          const response = await axios.get(`http://192.168.1.81:5000/api/dashboard/performance/recruiter/monthwise/${recruiterId}?metric=${metric}`);
  
          return response.data;
        },
      async fetchData() {
        this.loading = true;
        try {
          if (this.isMonthWise) {
            await this.fetchChartData();
          } else {
            if (this.selectedRecruiter) {
              // Fetch data for the selected recruiter and all metrics
              const shortlisted = await this.fetchMetric('shortlisted');
              const offered = await this.fetchMetric('offered');
              const joiners = await this.fetchMetric('joiners');
              const drops = await this.fetchMetric('drops');
              const revenue = await this.fetchMetric('revenue');
  
              this.performanceData = {
                shortlistedCount: shortlisted.shortlistedCount,
                offeredCount: offered.offeredCount,
                joinersCount: joiners.joinersCount,
                dropsCount: drops.dropsCount,
                totalRevenue: revenue.totalRevenue
              };
              this.topPerformers = [];
              this.bottomPerformers = [];
  
            } else {
              // Fetch top and bottom performers
              const topResponse = await axios.get(`http://192.168.1.81:5000/api/dashboard/performance/top?timeframe=${this.selectedTimeframe}`);
              const bottomResponse = await axios.get(`http://192.168.1.81:5000/api/dashboard/performance/bottom?timeframe=${this.selectedTimeframe}`);
              this.topPerformers = topResponse.data;
              this.bottomPerformers = bottomResponse.data;
              this.performanceData = {}; //Clear the data
            }
          }
  
        } catch (error) {
          console.error('Error fetching performance data:', error);
          this.performanceData = {}; // Reset data on error
          this.topPerformers = [];
          this.bottomPerformers = [];
        } finally {
          this.loading = false;
        }
      },
      async fetchMetric(metric) {
        try {
          const response = await axios.get(`http://192.168.1.81:5000/api/dashboard/performance/recruiter/${this.selectedRecruiter}?timeframe=${this.selectedTimeframe}&metric=${metric}`);
          return response.data;
        } catch (error) {
          console.log(`Error while fetching ${metric} `, error)
          return {}
        }
      },
      async searchData() {
        this.loading = true;
        this.selectedRecruiter = ''; //Clear filter
        try {
          const response = await axios.get(`http://192.168.1.81:5000/api/dashboard/performance/search?query=${this.searchQuery}`);
          if (typeof response.data === 'string' || response.data.message) {
            this.searchMessage = response.data.message || response.data;
            this.searchResults = null; // Clear previous results
            this.searchResultFields = [];
          } else {
            this.searchResults = response.data;
            this.searchMessage = null; // Clear any previous message
            // Dynamically generate fields based on the first item in searchResults
            if (Array.isArray(this.searchResults) && this.searchResults.length > 0) {
              this.searchResultFields = Object.keys(this.searchResults[0]).map(key => ({
                key: key,
                label: key.charAt(0).toUpperCase() + key.slice(1).replace(/([A-Z])/g, ' $1'), // Convert camelCase to spaced words
                sortable: true // Enable sorting
              }));
            }
  
          }
          //Clear all data
          this.topPerformers = []
          this.bottomPerformers = [];
          this.performanceData = {}
          this.chartSeries = []; // Clear chart data
  
        } catch (error) {
          console.error("Error during search:", error);
          this.searchMessage = "An error occurred during the search.";
          this.searchResults = null;
        } finally {
          this.loading = false;
        }
      },
  
      clearSearch() {
        this.searchQuery = '';
        this.searchMessage = null;
        this.searchResults = null;
        this.searchResultFields = [];
        this.fetchData(); // Refresh the data to the default view
      },
        clearSearchData(){
            this.searchMessage = null;
            this.searchResults = null;
            this.searchResultFields = [];
        }
  
    }
  };
  </script>